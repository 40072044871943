import {faFileDownload, faFilePdf, faShare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { IRouteParams } from "AppRoutes";
import Button from "components/ui/buttons/Button";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import { compareAsc, format, parse } from "date-fns";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import PrintDialog from "components/dialogs/PrintDialog";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import React, { useMemo, useState } from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import useSWR from "swr";
import { IQuote } from "../IQuote";
import { ShareQuoteDialog } from "./ShareQuoteDialog";
import { Link as RouterLink } from 'react-router-dom';
import { routes } from "routes";
import { useTracking } from "context/TrackingProvider";
import Amplitude, { AmplitudeProject } from 'utils/Amplitude';
import { getQuoteNumber } from "./getQuoteNumber";
import DeleteQuoteDialog from "./dialogs/DeleteQuoteDialog";
import useCurrentUser from "../../../loaders/useCurrentUser";
import QuotePDF from "./QuotePDF";

export const ViewQuote: React.VFC = () => {
  const { secret_code } = useParams<IRouteParams>();
  const { data: quote, error } = useSWR<IQuote>(endpoints.quote(secret_code));
  const quoteTeacherID = quote?.teacher_id ? quote?.teacher_id : null
  const { data: salesProfile } = useSWR<ISalesProfile>(quoteTeacherID ? endpoints.salesProfile(quoteTeacherID) : null);
  const quoteLoading = !quote && !error;
  const {currentUser} = useCurrentUser();

  const sharedClasses = useSharedStyles();
  const subtotal = useMemo(() => {
    if (!quote) {
      return 0;
    }

    return quote!.num_sites * (quote!.price_per_site);
  }, [quote]);

  const volumeDiscount = useMemo(() => {
    if (!quote) {
      return 0;
    }

    return quote!.num_sites * (quote!.discount_per_site);
  }, [quote]);

  const grandTotal = useMemo(() => {
    if (!quote) {
      return 0;
    }

    return quote!.num_sites * (quote!.price_per_site - quote!.discount_per_site) - (salesProfile?.recurring_custom_discount || 0);
  }, [quote]);

  const [printQuote, setPrintQuote] = useState(false);
  const shareQuoteDialogState = useDialogState(false);
  const deleteQuoteDialogState = useDialogState(false);
  const location = useLocation();
  const history = useHistory();
  const { track } = useTracking();
  const quoteNumber = getQuoteNumber(quote);

  const planHistory = salesProfile?.plan_history
  let planTermString = null;

  if (planHistory && planHistory.length > 0 && planHistory[0]?.end_date) {
    planTermString = getNewQuoteTermString(planHistory[0]?.end_date)
  }

  if (quoteLoading) {
    return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="300px">
      <CircularProgress />
    </Box>
  }

  if (error) {
    return <Box>
      <Alert severity="error">We couldn't load this quote. Please try again or contact support@kodable.com.</Alert>
    </Box>
  }

  const quoteExpired = compareAsc(parse(quote!.expiration_date, 'yyyy-MM-dd', new Date()), new Date()) < 0;
  const canOrder = 
    !quote?.has_been_used && 
    (!quote?.teacher_id || !salesProfile?.license_holder || salesProfile?.license_holder?.eligible_for_order) && 
    !quoteExpired;

  const ineligibleReason = () =>
  {
    if (quote?.has_been_used) {
      return "Quote already used";
    }
    if (quoteExpired) {
      return "Quote expired";
    }
    if (quote?.teacher_id && salesProfile?.license_holder && !salesProfile?.license_holder?.eligible_for_order)
    {
      if (salesProfile?.license_holder?.ineligible_for_order_reason == 'payment_pending') {
        return "Payment pending";
      }
      if (salesProfile?.license_holder?.ineligible_for_order_reason == 'delinquent') {
        return "Subscription delinquent";
      }
      if (salesProfile?.license_holder?.ineligible_for_order_reason == 'future_upgrade') {
        return "Future subscription exists";
      }
    }
    return '';
  }

  const handleRenewOrOrder = () => {

    // todo: check which events we need, as these two different things were getting sent for the two buttons we have

    track('Clicked on order from quote', {
      'Quote type': salesProfile?.license_holder?.eligible_for_order ? "renewal" : "new business"
    });

    if (salesProfile?.license_holder?.eligible_for_order) {
      Amplitude.identify(salesProfile.license_holder.id, AmplitudeProject.teacher);
      Amplitude.track('Clicked on "Renew Now" button', {
        'Plan Type': quote?.has_curriculum ? 'Premium+' : 'Premium',
        'Number of sites': quote?.num_sites,
        'Quote type': 'Renewal'
      });
      if (typeof (window as any).Intercom === 'function')
      {
        (window as any).Intercom('trackEvent', 'Clicked on "Renew Now" button');
      }
    }
  }

  return <Box mt={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <PrintDialog
      open={printQuote}
      onClose={() => setPrintQuote(false)}
      handout={<QuotePDF quote={quote!} planTermString={planTermString!} recurringCustomDiscount={salesProfile?.recurring_custom_discount}/>}
      filename={`Kodable Quote ${quoteNumber} for ${quote!.organization_name}`}
      v2
    />
    <ShareQuoteDialog
      {...shareQuoteDialogState}
      quote={quote!}
    />
    <DeleteQuoteDialog
      {...deleteQuoteDialogState}
      quoteId={quoteNumber}
      secret={quote?.secret_code}
      onSuccess={() => {history.goBack()}}
    />
    <Box width="100%" maxWidth="800px" className={sharedClasses.vspacing2} px={2}>
      <PageHeader title="" inAppBar actions={
        <Typography variant="h2">Quote #{quoteNumber}</Typography>
      } />

      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" className={sharedClasses.hspacing2} pb={2}>
            <Button
              size="large"
              variant="outlined"
              color="blue"
              startIcon={<FontAwesomeIcon icon={faShare} />}
              onClick={shareQuoteDialogState.handleOpen}
            >Share</Button>
            <Button
              size="large"
              variant="outlined"
              color="blue"
              startIcon={<FontAwesomeIcon icon={faFileDownload} />}
              onClick={() => setPrintQuote(true)}
            >Download as PDF</Button>
            {currentUser?.is_god && !quote?.has_been_used &&
              <Button
                size="large"
                variant="outlined"
                color="red"
                startIcon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => deleteQuoteDialogState.handleOpen()}
              >Delete</Button>
            }
            {(currentUser) && canOrder &&
              <Button
                size="large"
                variant="outlined"
                color="orange"
                startIcon={<FontAwesomeIcon icon={faEdit} />}
                onClick={() => {
                  history.push(routes.sales.quotes.editStart(quote?.secret_code));
                }
                }
              >Edit</Button>
            }
          </Box>
          <Box>
            <Button
              size="large"
              variant="contained"
              color= {canOrder? "blue" : "orange"}
              {...{
                component: RouterLink,
                to: routes.sales.orders.submitWithQuote(quote?.secret_code!)
              }}
              onClick={handleRenewOrOrder}
              disabled = {!canOrder}
            >
              {canOrder? salesProfile?.license_holder?.eligible_for_order ? "Renew Now" : "Order Now" : "Disabled: " + ineligibleReason()}
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box pb={1}>
        <Box pb={1} display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="space-between" flexDirection="row" width="100%" alignItems="center">
              <Typography variant="body1" style={{ marginRight: '50px' }}><strong>Quote Number: {quoteNumber}</strong></Typography>
              <KeyValuePair
                title="Quote Expiration Date"
                value={dbDateFormatToStandardUS(quote!.expiration_date)}
              />
            </Box>
        </Box>
          <Box display="flex" justifyContent="space-between">
            <AddressBlock />
            <ContactBlock />
          </Box>
      </Box>
      <Box pb={1} className={sharedClasses.vspacing2}>
        <Typography variant="h2">Order Submission Instructions</Typography>
        <ol>
          <li>1. Submit your order by visiting&nbsp;
            <a href={'https://kodable.com/quote'}>kodable.com/quote</a><br/>
            Use your unique quote number during submission: <span style={{fontWeight: 700, fontSize: 16}}>{quoteNumber}</span></li>
          <li>2. You will be able to upload your purchase order (if you have one) during order submission.</li>
          <li>3. After submission: <br/>
            Your invoice will be sent to the billing contact you specify.<br/>
            Payment is due 45 days after subscription start date.<br/>
            We accept purchase order, check, credit card, or bank transfer.
          </li>
        </ol>
        <Typography variant={'subtitle1'}>Problems submitting your order? Contact support@kodable.com</Typography>
      </Box>
      <Box pb={2} className={sharedClasses.vspacing2}>
          <Typography variant="h2">Documents</Typography>
          <Box display="flex" className={sharedClasses.hspacing2}>
            <Button 
              formTarget="_blank"
              startIcon={<FontAwesomeIcon icon={faFilePdf} />}
              size="large"
              variant="outlined"
              color="blue"
              href="https://s3.us-west-2.amazonaws.com/resources.kodable.com/other/kodable_w9_2024FINAL.pdf"
            >
                Download W-9
            </Button>
            <Button
              formTarget="_blank"
              startIcon={<FontAwesomeIcon icon={faFilePdf} />}
              size="large"
              variant="outlined"
              color="blue"
              href="https://s3.us-west-2.amazonaws.com/resources.kodable.com/other/SoleSourceLetter2022.pdf"
            >
              Download Sole Source Letter
            </Button>
          </Box>
        </Box>
      <Header>Organization Details</Header>
      <Box p={2} className={sharedClasses.vspacing2}>
        <KeyValuePair
          title="Organization Name"
          value={quote!.organization_name}
          flexGrow={1}
        />
        <Box display="flex" className={sharedClasses.hspacing2} justifyContent="space-between">
          <KeyValuePair
            title="Contact Name"
            value={quote!.contact_name}
            titleMaxWidth={'80px'}
          />
          <KeyValuePair
            title="Contact Email"
            value={quote!.contact_email}
            titleMaxWidth={'80px'}
          />
        </Box>
        <Box display="flex" className={sharedClasses.hspacing2} justifyContent="space-between">
          <KeyValuePair
            title="Shipping Cost"
            value="FREE"
          />
          <KeyValuePair
            title="Quote Number"
            value={quoteNumber}
          />
        </Box>
      </Box>
      <Header>Purchase Details</Header>
      <Box pb={2} px={2} className={sharedClasses.vspacing4} style={{ pageBreakAfter: 'always' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Term</TableCell>
                <TableCell># Sites</TableCell>
                <TableCell align="right">List Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Premium{quote!.has_curriculum ? '+' : ''}</b>
                  <br/>
                  <Typography variant={'subtitle2'}>{quote!.has_curriculum ? 'Unlimited Students per Site' : '250 Student Limit'}</Typography>
                  <Typography variant={'subtitle2'}>{quote!.has_curriculum ? 'Clever Access Included' : 'No Clever Access'}</Typography>
                </TableCell>
                <TableCell>
                  <b>12 Months</b>
                  <br/>
                  <Typography variant={'subtitle2'}>{planTermString !== null ? `${planTermString}` : ''}</Typography>
                </TableCell>
                <TableCell>{quote!.num_sites}</TableCell>
                <TableCell align="right">${quote!.price_per_site.toLocaleString('en-US')} USD</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box mx={1} display="flex" flexDirection="column" alignItems="flex-end" className={sharedClasses.vspacing1}>
          {(!!volumeDiscount || !!salesProfile?.recurring_custom_discount) && <>
            <KeyValuePair
              title="Subtotal"
              value={`$${subtotal.toLocaleString('en-US')} USD`}
              flexGrow={0}
            />
            {!!volumeDiscount &&<KeyValuePair
              title="Volume discount"
              value={`$${volumeDiscount.toLocaleString('en-US')} USD`}
              flexGrow={0}
            />}
            {!!salesProfile?.recurring_custom_discount &&<KeyValuePair
              title="Recurring discount"
              value={`$${salesProfile?.recurring_custom_discount.toLocaleString('en-US')} USD`}
              flexGrow={0}
            />}
          </>
          }
          <KeyValuePair
            title="Total"
            value={`$${grandTotal.toLocaleString('en-US')} USD`}
            flexGrow={0}
          />
        </Box>
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" pt={2} pb={4}>
          <Box display="flex" className={sharedClasses.hspacing2}>
            <Button
              size="large"
              variant="outlined"
              color="blue"
              startIcon={<FontAwesomeIcon icon={faShare} />}
              onClick={shareQuoteDialogState.handleOpen}
            >Share</Button>
            <Button
              size="large"
              variant="outlined"
              color="blue"
              startIcon={<FontAwesomeIcon icon={faFileDownload} />}
              onClick={() => setPrintQuote(true)}
            >Download as PDF</Button>
          </Box>
          <Box>
            <Button
              size="large"
              variant="contained"
              color= {canOrder? "blue" : "orange"}
              {...{
                component: RouterLink,
                to: routes.sales.orders.submitWithQuote(quote?.secret_code!)
              }}
              onClick={handleRenewOrOrder}
              disabled = {!canOrder}
            >
              {canOrder? salesProfile?.license_holder?.eligible_for_order ? "Renew Now" : "Order Now" : "Disabled: " + ineligibleReason()}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
}

function dbDateFormatToStandardUS(date: string) : string {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'M/d/yyyy')
}

function getNewQuoteTermString(endDate: string | null) : string | null {
  if (endDate === null) {
    return endDate;
  }
  // Use Zulu/GMT time
  const newEndDateEpoch = Date.parse(endDate + 'T00:00:00.000Z')
  // Convert to Date object & compare to today
  const newEndDate = new Date(newEndDateEpoch)
  const today = new Date()
  // Don't try to calculate/display term if previous user subscription is expired
  if (today >= newEndDate) {
    return null;
  }
  // Add 1 year to end date
  newEndDate.setFullYear(newEndDate.getFullYear() + 1)
  // Return quote term string (yyyy/mm/dd - yyyy/mm/dd)
  return `${dbDateFormatToStandardUS(endDate)} - ${dbDateFormatToStandardUS(newEndDate?.toISOString().split('T')[0])}`
}

const AddressBlock: React.VFC = () => <Box>
  <Typography>SurfScore, Inc.</Typography>
  <Typography>434 W. Cedar St. #200</Typography>
  <Typography>San Diego, CA 92101</Typography>
</Box>

const ContactBlock: React.VFC = () => <Box>
  <Typography>Phone: 415-993-1020</Typography>
</Box>

const Header: React.FC = ({ children }) => {
  return <Box mt={2} p={1} width="100%" bgcolor="#27a9e1" color="white" display="flex" justifyContent="center">
    <Typography variant="h1">{children}</Typography>
  </Box>
}

const KeyValuePair: React.VFC<{ title: string, value: React.ReactNode, flexGrow?: number, titleMaxWidth?: string, maxWidth?: string}> = ({ title, value, flexGrow = 1, maxWidth='600px', titleMaxWidth='500px' }) => {
  const sharedClasses = useSharedStyles();

  return <Box display="flex" className={sharedClasses.hspacing1} justifyContent="space-between" alignItems="center" style={{ gap: '0px', flexGrow }}>
    <Box fontSize="1.25rem" style={{margin: '0px 0px', flexGrow: 0, maxWidth: titleMaxWidth}} >{title}:</Box>
    <Box style={{minWidth: '180px', maxWidth, margin: '0px 0px'}}  px={1.0} py={0.5} flexGrow={flexGrow} bgcolor="lightgray" textAlign={flexGrow === 0 ? 'right' : undefined}>
      <Typography style={{ wordWrap: 'break-word'}}>{value}</Typography>
    </Box>
  </Box>
}
