import {Document, Font, Page, StyleSheet, Text, View, Svg, Line} from "@react-pdf/renderer";
import React from "react";
import {IQuote} from "../IQuote";
import {format, parse} from "date-fns";
import {getQuoteNumber} from "./getQuoteNumber";


function dbDateFormatToStandardUS(date: string) : string {
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'M/d/yyyy')
}

const QuotePDF: React.VFC<{quote: IQuote, planTermString: string, recurringCustomDiscount: number | undefined}> = ({quote, planTermString, recurringCustomDiscount}) => {
  const quoteNumber = getQuoteNumber(quote);
  const subtotal = quote.num_sites * (quote.price_per_site);
  const volumeDiscount = quote.num_sites * (quote.discount_per_site);
  const grandTotal = quote!.num_sites * (quote.price_per_site - quote.discount_per_site) - (recurringCustomDiscount || 0);
  
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.sectionHeader}>
          <Text style={styles.address}>Surfscore, Inc</Text>
          <Text style={styles.address}>
            434 W. Cedar St. #200, San Diego, CA 92101
          </Text>
          <Text style={styles.address}>Phone: 415-993-1020</Text>
        </View>
        <View style={styles.expirationDate}>
          <Text>
            Quote Expiration Date:{" "}
            {dbDateFormatToStandardUS(quote.expiration_date)}
          </Text>
        </View>
        <View style={styles.title}>
          <Text>Kodable Quote</Text>
        </View>
        <View style={styles.instructions}>
          <Text style={styles.instructionsHeader}>
            Order Submission Instructions
          </Text>
          <Text>1. Submit your order by visiting kodable.com/quote</Text>
          <Text>
            Use your unique quote number during submission:{" "}
            <Text style={{ fontFamily: "Helvetica-Bold" }}>{quoteNumber}</Text>
          </Text>
          <Text>
            2. You will be able to upload your purchase order (if you have one)
            during order submission.
          </Text>
          <Text>3. After submission:</Text>
          <Text>
            Your invoice will be sent to the billing contact you specify.
          </Text>
          <Text>Payment is due 45 days after subscription start date.</Text>
          <Text>
            We accept purchase order, check, credit card, or bank transfer.
          </Text>
        </View>
        <View style={styles.support}>
          <Text>
            Problems submitting your order? Contact support@kodable.com
          </Text>
        </View>
        <View style={styles.sectionHeader}>
          <Text>Organization Details</Text>
        </View>
        <View style={styles.organizationDetails}>
          <View style={styles.infoRow}>
            <View style={{ ...styles.infoCell, width: "100%" }}>
              <Text>Organization Name:</Text>
              <View style={styles.infoBox}>
                <Text style={styles.infoText}>{quote.organization_name}</Text>
              </View>
            </View>
          </View>

          <View style={styles.infoRow}>
            <View style={{ ...styles.infoCell, width: "50%" }}>
              <Text>Contact Name:</Text>
              <View style={styles.infoBox}>
                <Text style={styles.infoText}>{quote.contact_name}</Text>
              </View>
            </View>
            <View style={{ ...styles.infoCell, width: "50%" }}>
              <Text>Contact Email:</Text>
              <View style={styles.infoBox}>
                <Text style={styles.infoText}>{quote.contact_email}</Text>
              </View>
            </View>
          </View>

          <View style={styles.infoRow}>
            <View style={{ ...styles.infoCell, width: "50%" }}>
              <Text>Shipping Cost:</Text>
              <View style={styles.infoBox}>
                <Text style={styles.infoText}>FREE</Text>
              </View>
            </View>
            <View style={{ ...styles.infoCell, width: "50%" }}>
              <Text>Quote Number:</Text>
              <View style={styles.infoBox}>
                <Text style={styles.infoText}>{quoteNumber}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.sectionHeader}>
          <Text>Purchase Details</Text>
        </View>
        <View style={styles.table}>
          <View style={{ ...styles.tableRow, fontSize: "10" }}>
            <Text style={styles.tableCell}>Item</Text>
            <Text style={styles.tableCell}>Term</Text>
            <Text style={styles.tableCell}>Number of Sites</Text>
            <Text style={{ ...styles.tableCell, textAlign: "right" }}>
              List Price
            </Text>
          </View>

          <Svg height="10" width="612">
            <Line
              x1="0"
              y1="5"
              x2="552"
              y2="5"
              strokeWidth="2"
              stroke="#f0f0f0"
            />
          </Svg>

          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.bold}>
                Kodable Premium{quote.has_curriculum ? "+" : ""}
              </Text>

              <Text style={styles.subtitle}>
                {quote!.has_curriculum
                  ? "Unlimited Students per Site"
                  : "250 Student Limit"}
              </Text>
              <Text style={styles.subtitle}>
                {quote!.has_curriculum
                  ? "Clever Access Included"
                  : "No Clever Access"}
              </Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.bold}>12 Months</Text>
              <Text style={styles.subtitle}>{planTermString !== null ? planTermString : ""}</Text>
            </View>
            <Text style={styles.tableCell}>{quote.num_sites}</Text>
            <Text style={{ ...styles.tableCell, textAlign: "right" }}>
              ${quote.price_per_site.toLocaleString("en-US")} USD
            </Text>
          </View>

          <Svg height="10" width="612">
            <Line
              x1="0"
              y1="5"
              x2="552"
              y2="5"
              strokeWidth="2"
              stroke="#f0f0f0"
            />
          </Svg>
        </View>
        <View style={styles.resultsTable}>
          <View style={{ flexGrow: 5 }}></View>
          { ((volumeDiscount && volumeDiscount > 0) || (!!recurringCustomDiscount)) && (
            <>
              <View style={styles.resultsRow}>
                <Text>Subtotal:</Text>
                <View style={styles.resultsCell}>
                  <Text style={styles.resultsText}>
                    ${subtotal.toLocaleString("en-US")} USD
                  </Text>
                </View>
              </View>

              {(volumeDiscount && volumeDiscount > 0) &&
                <View style={styles.resultsRow}>
                  <Text>Volume Discount:</Text>
                  <View style={styles.resultsCell}>
                    <Text style={styles.resultsText}>
                      ${volumeDiscount.toLocaleString("en-US")} USD
                    </Text>
                  </View>
                </View>
              }
              {(!!recurringCustomDiscount) &&
                <View style={styles.resultsRow}>
                  <Text>Recurring Discount:</Text>
                  <View style={styles.resultsCell}>
                    <Text style={styles.resultsText}>
                      ${recurringCustomDiscount.toLocaleString("en-US")} USD
                    </Text>
                  </View>
                </View>
              }
            </>
          )}
          <View style={styles.resultsRow}>
            <Text>Total:</Text>
            <View style={styles.resultsCell}>
              <Text style={styles.resultsText}>
                ${grandTotal.toLocaleString("en-US")} USD
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

Font.register({
  family: 'OpenSans',
  src: '/fonts/OpenSans-Regular.ttf'
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: 'OpenSans',
    width: "100%",
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 12,
    gap: 7,
    lineHeight: '1.1em'
  },
  subtitle: {
    fontSize: 10,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  sectionHeader: {
    fontSize: 20,
    backgroundColor: '#27a9e1',
    color: 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  address: {
    fontSize: 12,
    textAlign: 'left',
  },
  expirationDate: {
    fontSize: 12,
    textAlign: 'right',
    width: '100%',
    fontFamily: 'Helvetica-Bold'
  },
  title: {
    fontSize: 36,
    textAlign: 'center',
  },
  instructionsHeader: {
    textAlign: 'left',
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    paddingBottom: '10'
  },
  instructions: {
    textAlign: 'left',
    fontSize: 10,
    width: '100%',
    gap: 2
  },
  support: {
    textAlign: 'left',
    fontSize: 12,
    width: '100%',
  },
  organizationDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10'
  },
  infoCell: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10',
    alignItems: 'center'
  },
  infoRow:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '10',
  },
  infoBox:{
    backgroundColor: '#f0f0f0',
    flexGrow: 1,
    alignItems: 'center',
    padding: 5,
  },
  infoText:{
    textAlign: 'left',
    width: '100%',
  },
  table:{
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '100%',
    gap: '5',
  },
  tableRow:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '10',
  },
  tableCell:{
    textAlign: 'left',
    width: '25%',
  },
  resultsTable: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
    width: '50%',
    flexGrow: 5,
    marginBottom: 30
  },
  resultsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: '10',
    marginTop: '5',
  },
  resultsCell:{
    backgroundColor: '#f0f0f0',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  resultsText:{
    textAlign: 'right',
  }
});


export default QuotePDF;